function seededRandom(seed: number) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function mapAddressToConstellation(address: string): string {
  const constellations = [
    "Andromeda", "Antlia", "Apus", "Aquarius", "Aquila", "Ara", "Aries", "Auriga", "Boötes",
    "Caelum", "Camelopardalis", "Cancer", "Capricornus", "Carina", "Cassiopeia", "Centaurus",
    "Cepheus", "Cetus", "Chamaeleon", "Circinus", "Columba", "Corvus", "Crater", "Crux",
    "Cygnus", "Delphinus", "Dorado", "Draco", "Equuleus", "Eridanus", "Fornax", "Gemini",
    "Grus", "Hercules", "Horologium", "Hydra", "Hydrus", "Indus", "Lacerta", "Leo", "Lepus",
    "Libra", "Lupus", "Lynx", "Lyra", "Mensa", "Microscopium", "Monoceros", "Musca", "Norma",
    "Octans", "Ophiuchus", "Orion", "Pavo", "Pegasus", "Perseus", "Phoenix", "Pictor",
    "Pisces", "Puppis", "Pyxis", "Reticulum", "Sagitta", "Sagittarius", "Scorpius", "Sculptor",
    "Scutum", "Serpens", "Sextans", "Taurus", "Telescopium", "Triangulum", "Tucana", "Vela",
    "Virgo", "Volans", "Vulpecula"
  ];

  const consonants = "BCDFGHJKLMNPQRSTVWXYZ";
  const vowels = "AEIOU";

  const seed = address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

  if (!Number.isInteger(seed)) {
    throw new Error("Seed is not an integer");
  }

  const starName = constellations[seed % constellations.length];

  const getRandomChar = (chars: string, offset: number) => chars[Math.floor(seededRandom(seed + offset) * chars.length)];
  let suffix = getRandomChar(consonants, 1) + getRandomChar(vowels, 2) + getRandomChar(consonants, 3);

  if (suffix[0] === 'N' && suffix[2] === 'G') {
    suffix = suffix.slice(0, 2) + 'L';
  }

  return `${starName}-${suffix}`;
}

export function mapAddressToGradient(
  id: string,
  opacity: number = 1,
  minLightness: number = 30,
  maxLightness: number = 70
) {
  if (!id) {
    return { angle: '0deg', stop1: '#000000', stop2: '#000000' };
  }

  const hash = id.split('').reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  const angle = Math.floor(seededRandom(hash) * 360);

  const hue1 = Math.floor(seededRandom(hash + 1) * 360);
  const hue2 = Math.floor(seededRandom(hash + 2) * 360);
  const saturation = 70;

  const lightness1 = minLightness + Math.floor(seededRandom(hash + 3) * (maxLightness - minLightness));
  const lightness2 = minLightness + Math.floor(seededRandom(hash + 4) * (maxLightness - minLightness));

  const hexColor1 = HSLToHex(hue1, saturation, lightness1, opacity);
  const hexColor2 = HSLToHex(hue2, saturation, lightness2, opacity);

  return { angle: `${angle}deg`, stop1: hexColor1, stop2: hexColor2 };
}

function HSLToHex(h: number, s: number, l: number, a: number = 1): string {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c / 2;
  let r = 0, g = 0, b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  const alphaHex = a < 1 ? Math.round(a * 255).toString(16).padStart(2, '0') : '';

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}${alphaHex}`;
}